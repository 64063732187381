@import "~antd/dist/antd.less";
iframe{
    border:none;
}
.up{
    color: #03CE3C;
}
.down{
    color: #F64242;
}
@primary-color: #5542F6;