.social-page{
    display: grid;
    grid-gap: 25px;
    grid-template-columns: minmax(380px, calc(100% - 305px)) 280px;
    width: 100%;

    @media (max-width: 768px) {
        grid-template-columns: 100%;
    }
    .ant-card{
        margin-bottom: 10px;
    }
    .myInfo{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .avatar{
            margin-right: 20px;
            border: 1px solid #5542F6;
            border-radius: 50%;
        }
    }
    .social-content{
        position: relative;
        .topic-tag{
            position: absolute;
            z-index: 10;
            right: 14px;
            top: 10px;
            color: #5542F6;
            border-radius: 40px;
            height: 24px;
            padding: 0 9px;
            .ant-tag-close-icon{
                color: #CEBBEF;
            }
        }
        .ant-card-body{
            padding: 0;
        }
        .ant-tabs{
            .ant-tabs-tab{
                width: 120px;
                margin-left: 0;
                text-align: center;
                font-weight: 400;
                font-size: 14px;
                justify-content: center;
                color: #000;
                &.ant-tabs-tab-active{
                    font-weight: 700;
                    .ant-tabs-tab-btn{
                        color: #000;
                    }
                }
            }
            .ant-tabs-tabpane{
                display: none;
            }
        }
        .ant-avatar{
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .ant-comment{
        padding: 20px 36px;
        @media (max-width: 768px) {
            padding: 20px 16px;
        }
        .ant-comment-actions{
            display: inline-flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
            li{
                cursor: pointer;
                margin-right: 10px;
                margin-bottom: 10px;
                &:first-of-type{
                    display: flex;
                }
            }
        }
        .ant-comment-content{
            position: relative;
        }
        .ant-comment-content-author{
            width: calc(100% - 100px);
        }
        .ant-comment-content-author-name{
            font-weight: 700;
            font-size: 16px;
            color: #000;
            & > *{
                color: #000;
            }
            span{
                font-weight: 400;
                font-size: 14px;
                color: #000;
            }
        }
        .ant-comment-content-author-time{
            color: #9A9A9A;
            font-weight: 400;
            font-size: 14px;
        }
        .ant-comment-content-detail{
            padding: 5px 0;
            .content{
                border: 1px solid #DEDEDE;
                color: #000;
                padding: 8px 16px;
                margin-top: 10px;
                width: calc(100% - 100px);
                @media (max-width: 992px) {
                    width: calc(100% - 60px);
                }
                @media (max-width: 768px) {
                    width: calc(100% - 40px);
                }
            }
            .more{
                color: #1F90CF;
                font-size: 12px;
                float: right;
                line-height: 26px;
            }
            .extra{
                position: absolute;
                top: -5px;
                right: 0;
                cursor: pointer;
            }
        }

    }

    .comment-action{
        &-up,&-down{
            border: 1px solid #DEDEDE;
            padding: 0 8px;
            height: 28px;
            line-height: 28px;
            width: 80px;
            font-size: 12px;
            display: flex;
            border-radius: 1px;
            justify-content: space-between;
            align-items: center;
            &.selected{
                border: none;
            }
        }
        &-up{
            margin-right: 10px;
            &.active{
                border: none;
                background: #E4FCEA;
                font-weight: 600;
                color: #000
            }
        }
        &-down{
            &.active{
                border: none;
                background: #FFECEC;
                font-weight: 600;
                color: #000
            }
        }
    }
    .comment-price{
        background: #E4FCEA;
        border-radius: 1px;
        padding: 0 8px;
        height: 28px;
        line-height: 28px;
        color: #03CE3C;
        &.down{
            background: #FFECEC;
            color: #F64242;
        }
    }
    .treding-card{
        .ant-card-body{
            padding: 0;
        }
        .item{
            border-bottom: 1px solid #EBEAED;
            padding: 10px 24px;
            cursor: pointer;
            h1{
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 0;
            }
            h2{
                font-weight: 400;
                font-size: 16px;
                color: #000;
                margin-bottom: 0;
            }
            p{
                font-weight: 400;
                font-size: 12px;
                color: #84818A;
                margin-bottom: 0;
            }
            &.active{
                h2{
                    color: #816eff;
                    font-weight: 800;
                }
                p{
                    color: #000;
                }
            }
        }
    }
}
.extra-content{
    p{
        display: flex;
        align-items: center;
        cursor: pointer;
        svg{
            margin-right: 10px;
        }
    }
}
@primary-color: #5542F6;